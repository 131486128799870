<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1"> </b-row>
      </template>
    </header-slot>
    <b-row>
      <b-col cols="12" lg="6" class="mb-1">
        <general-card :key="retainerFeeKey" title="Retainer FEE">
          <template #body-header>
            <card-retainer-fee
              :program-id="programId"
              @open="openDetailedFeeModal($event)"
            />
          </template>
          <template #body-content>
            <main-charts-retainer-fee :program-id="programId" />
          </template>
        </general-card>
      </b-col>
      <b-col cols="12" lg="6" class="mb-1">
        <general-card :key="tooNegotiateKey" title="To Negotiate">
          <template #body-header>
            <card-negotiate @open="openDetailedNegotiateModal($event)" />
          </template>
          <template #body-content>
            <main-charts-negotiate />
          </template>
        </general-card>
      </b-col>
      <b-col cols="12" lg="6" class="mb-1">
        <general-card :key="settlementsKey" title="Settlements">
          <template #body-header>
            <card-settlements @open="openDetailedSettlementModal($event)" />
          </template>
          <template #body-content>
            <main-charts-settlements />
          </template>
        </general-card>
      </b-col>
      <b-col cols="12" lg="6" class="mb-1">
        <general-card :key="othersKey" title="Others">
          <template #body-header>
            <card-others
              :program-id="programId"
              @open="openDetailedOthersModal($event)"
            />
          </template>
          <template #body-content>
            <main-charts-others :program-id="programId" />
          </template>
        </general-card>
      </b-col>

      <b-col cols="6" lg="6">
        <automatic-client-payments
          :key="keyAutomaticPayments"
          :program-id="programId"
          :show-filter-principal="true"
        />
      </b-col>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <call-analysis
          ref="callAnalysis"
          :select-year="selectYear"
          :select-month="selectMonth"
          :module-id="5"
        >
          <template #calendar>
            <div class="d-flex justify-content-end align-items-center py-1">
              <v-select
                v-model="selectYear"
                :reduce="(year) => year.id"
                :options="years"
                :selectable="(option) => option.selectable === true"
                :clearable="false"
                label="name"
                style="width: 150px"
                class="mr-1"
              />

              <v-select
                v-model="selectMonth"
                :reduce="(month) => month.id"
                :options="months"
                :clearable="false"
                label="name"
                style="width: 190px"
              />
            </div>
          </template>
        </call-analysis>
      </b-col>
      <b-col cols="6" class="mt-1">
        <DepartmentExpenses />
      </b-col>
    </b-row>
    <detailed-negotiate
      v-if="showDetailedBalance"
      :default-status="negotiateCardStatus"
      @close="closeDetailedNegotiateModal()"
    />
    <detailed-settlement
      v-if="showDetailedSettlement"
      @close="closeDetailedSettlementModal()"
    />
    <detailed-others
      v-if="showDetailedOthers"
      :default-type="othersCardType"
      @close="closeDetailedOthersModal()"
    />
    <detailed-fee
      v-if="showDetailedFee"
      :default-type="feeCardType"
      @close="closeDetailedFeeModal()"
    />
  </div>
</template>
<script>
import GeneralCard from "@/views/debt-solution/views/dashboard/components/GeneralCard.vue";
import CardNegotiate from "@/views/debt-solution/views/dashboard/components/negotiate/CardNegotiate.vue";
import CardSettlements from "@/views/debt-solution/views/dashboard/components/settlements/CardSettlements.vue";
import CardOthers from "@/views/debt-solution/views/dashboard/components/others/CardOthers.vue";
import CardRetainerFee from "@/views/debt-solution/views/dashboard/components/retainer-fee/CardRetainerFee.vue";
import DetailedNegotiate from "@/views/debt-solution/views/dashboard/components/negotiate/modals/DetailedNegotiate.vue";
import DetailedSettlement from "@/views/debt-solution/views/dashboard/components/settlements/modals/DetailedSettlement.vue";
import DetailedOthers from "@/views/debt-solution/views/dashboard/components/others/modals/DetailedOthers.vue";
import DetailedFee from "@/views/debt-solution/views/dashboard/components/retainer-fee/modals/DetailedFee.vue";
import MainChartsNegotiate from "@/views/debt-solution/views/dashboard/components/negotiate/MainCharts.vue";
import MainChartsOthers from "@/views/debt-solution/views/dashboard/components/others/MainCharts.vue";
import MainChartsSettlements from "@/views/debt-solution/views/dashboard/components/settlements/MainCharts.vue";
import MainChartsRetainerFee from "@/views/debt-solution/views/dashboard/components/retainer-fee/MainCharts.vue";
import AutomaticClientPayments from "@/views/commons/components/payments/views/components/AutomaticClientPayments.vue";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  name: "NewDsDashboard",
  components: {
    GeneralCard,
    CardNegotiate,
    CardSettlements,
    CardOthers,
    CardRetainerFee,
    DetailedNegotiate,
    DetailedSettlement,
    DetailedOthers,
    DetailedFee,
    MainChartsNegotiate,
    MainChartsOthers,
    MainChartsSettlements,
    MainChartsRetainerFee,
    AutomaticClientPayments,
    CallAnalysis,
    DepartmentExpenses
  },
  data() {
    return {
      showDetailedBalance: false,
      showDetailedSettlement: false,
      showDetailedOthers: false,
      showDetailedFee: false,
      negotiateCardStatus: null,
      othersCardType: null,
      selectMonth: null,
      selectYear: null,
      retainerFeeKey: Math.random(),
      tooNegotiateKey: Math.random(),
      settlementsKey: Math.random(),
      othersKey: Math.random(),
      keyAutomaticPayments: Math.random(),
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      optionsYears: [],
      today: new Date(),
    };
  },
  computed: {
    programId() {
      return 4;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    months() {
      if (this.selectYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      // Reset selectable mode for all years
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      // Disable future years if the selected month is greater than the current month
      const currentMonth = this.today.getMonth() + 1;
      if (this.selectMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },
  },
  mounted() {},
  created() {
    this.generateYears();
    this.currentMonth();
  },
  methods: {
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.selectMonth = currentMonth;
    },
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.selectYear = currentYear;
    },
    openDetailedNegotiateModal(negotiateCard) {
      this.negotiateCardStatus = negotiateCard.status;
      this.showDetailedBalance = true;
    },
    closeDetailedNegotiateModal() {
      this.negotiateCardStatus = null;
      this.showDetailedBalance = false;
    },
    openDetailedSettlementModal() {
      this.showDetailedSettlement = true;
    },
    closeDetailedSettlementModal() {
      this.showDetailedSettlement = false;
    },
    openDetailedOthersModal(othersCard) {
      this.othersCardType = othersCard.type;
      this.showDetailedOthers = true;
    },
    closeDetailedOthersModal() {
      this.othersCardType = null;
      this.showDetailedOthers = false;
    },
    openDetailedFeeModal(feeCard) {
      this.feeCardType = feeCard.type;
      this.showDetailedFee = true;
    },
    closeDetailedFeeModal() {
      this.feeCardType = null;
      this.showDetailedFee = false;
    },
  },
};
</script>
<style lang="scss">
</style>

<template>
  <div class="main-charts-settlement-fee">
    <div class="settlement-fee-line-chart">
      <div
        v-b-tooltip.hover="!selectedYear ? '' : 'Return to Years'"
        class="actions-chart"
        :class="selectedYear ? 'bg-success cursor-pointer' : 'bg-secondary'"
        @click="returnToYears()"
        :disabled="!selectedYear"
      >
        <feather-icon icon="CalendarIcon" size="20" />
      </div>
      <e-charts ref="settlementFeeChart" :options="chartOptions" autoresize />
    </div>
  </div>
</template>
<script>
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/chart/line";

export default {
  components: {
    ECharts,
  },
  data() {
    return {
      annualSettlementdata: [],
      selectedYear: null,
    };
  },
  computed: {
    color() {
      return this.isDarkSkin ? "#ffffff" : "#606060";
    },
    dataYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 4; i >= 0; i--) {
        years.push(currentYear - i);
      }
      return years;
    },
    dataMonths() {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    },
    xAxisData() {
      if (this.selectedYear) {
        return this.dataMonths;
      }
      return this.dataYears;
    },
    chartOptions() {
      return {
        title: {
          text: `Settlements ${
            this.selectedYear ? `by months (${this.selectedYear})` : "by years"
          }`,
          left: 55,
          top: 10,
          textStyle: {
            color: this.color,
            fontSize: 16,
            fontWeight: "600",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let tooltipString = "";
            const month = params[0].axisValue;
            tooltipString += `<div>${month}</div>`;
            params.forEach((param) => {
              let { value } = param;
              const { color } = param;
              const { seriesName } = param;
              value = this.formatCurrency(value);
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center; gap: 1.5rem;">';
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center;">';
              tooltipString += `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;"></span>`;
              tooltipString += `<span>${seriesName}</span>`;
              tooltipString += "</div>";
              tooltipString += `<strong>${value}</strong>`;
              tooltipString += "</div>";
            });
            return tooltipString;
          },
        },
        legend: {
          data: ["Total Amount", "Cost", "Fee"],
          color: ["#0090E7", "#00DD31", "#FF8E00"],
          textStyle: {
            color: this.color,
            fontSize: 14,
            fontWeight: "600",
          },
          itemHeight: 25,
          itemWidth: 25,
          right: 25,
          top: 10,
          icon: "roundRect",
          iconOption(name, itemStyle) {
            const borderRadius = [12, 12, 12, 12];
            return {
              width: itemStyle.itemWidth,
              height: itemStyle.itemHeight,
              borderRadius,
            };
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: this.color,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.color,
            },
          },
          axisLabel: {
            color: this.color,
            margin: 10,
            fontSize: 14,
            fontWeight: "500",
          },
          triggerEvent: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.color,
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
          axisLabel: {
            margin: 10,
            fontSize: 15,
            fontWeight: "500",
            color: this.color,
            formatter(value) {
              return `$${value.toLocaleString()}`;
            },
          },
          nameTextStyle: {
            color: this.color,
          },
        },
        series: [
          {
            name: "Total Amount",
            type: "line",
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              color: "#0090E7",
            },
            data: this.annualPaid,
          },
          {
            name: "Cost",
            type: "line",
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              color: "#00DD31",
            },
            data: this.annualTotalCost,
          },
          {
            name: "Fee",
            type: "line",
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              color: "#FF8E00",
            },
            data: this.annualFee,
          },
        ],
      };
    },
    annualFee() {
      return this.annualSettlementdata.map((a) => a.fee);
    },
    annualPaid() {
      return this.annualSettlementdata.map((a) => a.paid);
    },
    annualTotalCost() {
      return this.annualSettlementdata.map((a) => a.total_cost);
    },
  },
  async created() {
    await this.getAnnualSettlementData();
  },
  mounted() {
    this.handleChartClick();
  },
  methods: {
    async getAnnualSettlementData() {
      try {
        this.addPreloader();
        this.annualSettlementdata = [];
        const params = {
          year: this.selectedYear,
        };
        const { data } = await DashboardService.getDebtSolutionAnnualSettlement(
          params
        );
        this.annualSettlementdata = data.map((item) => ({
          ...item,
          fee: (item.fee || "0.00").replace(/,/g, ""),
          paid: (item.paid || "0.00").replace(/,/g, ""),
          total_cost: (item.total_cost || "0.00").replace(/,/g, ""),
        }));
      } catch (err) {
        this.showErrorSwal(err);
      } finally {
        this.removePreloader();
      }
    },
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
    handleChartClick() {
      this.$nextTick(() => {
        const { chart } = this.$refs.settlementFeeChart;
        chart.getZr().on("click", (params) => {
          const xAxisValue = params?.target?.eventData?.value || null;
          if (
            !this.dataYears.includes(parseInt(xAxisValue)) ||
            this.selectedYear
          ) {
            return;
          }
          this.selectedYear = xAxisValue;
          this.getAnnualSettlementData();
        });
      });
    },
    async returnToYears() {
      if (!this.selectedYear) return;
      this.selectedYear = null;
      await this.getAnnualSettlementData();
    },
  },
};
</script>
<style lang="scss">
.settlement-fee-line-chart {
  position: relative;
  .echarts {
    width: 100% !important;
    margin: auto;
  }
  .actions-chart {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 20px;
    z-index: 999;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;
    color: #fff;
  }
}
.main-charts-settlement-fee {
  width: 100%;
  box-shadow: 0px 0px 12px 0px #0000001a;
  padding: 1rem 0;
  border-radius: 0.3rem;
}
.dark-layout {
  .main-charts-settlement-fee {
    background: #111113;
  }
}
</style>

<template>
  <div class="to-negotiate-chart">
    <e-charts
      ref="line"
      :options="chartOptions"
      autoresize
    />
  </div>
</template>
<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/pie';

export default {
  components: {
    ECharts,
  },
  props: {
    statusComparison: {
      type: Object,
      required: false,
      default: () => ({
        active: 0,
        loyal: 0,
        outstanding: 0,
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    borderColor() {
      return this.isDarkSkin ? '#111113' : '#ffffff';
    },
    color() {
      return this.isDarkSkin ? '#ffffff' : '#606060';
    },
    totalClients() {
      return (
        this.statusComparison.active
        + this.statusComparison.loyal
        + this.statusComparison.outstanding
      );
    },
    chartOptions() {
      return {
        title: {
          text: 'Status Comparison',
          left: 20,
          top: 10,
          textStyle: {
            color: this.color,
            fontSize: 16,
            fontWeight: '600',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter(params) {
            let tooltipString = '';
            const status = params.data.name;
            const { value } = params.data;
            const percentage = params.percent;
            const { color } = params;
            tooltipString = `<div> Status </div>
                       <div style="display: flex; justify-content: space-between; align-items: center; gap: 1.5rem;">
                       <div style="display: flex; justify-content: space-between; align-items: center;">
                       <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;"></span>
                       <span>${status}</span>
                       </div>
                       <strong>${value} (${percentage}%)</strong>
                       </div>`;

            return tooltipString;
          },
        },
        legend: {
          data: ['Active', 'Loyal', 'Hold'],
          itemHeight: 25,
          itemWidth: 25,
          bottom: '2%',
          left: 'center',
          orient: 'vertical',
          color: ['#00DD31', '#0090E7', '#FF9900'],
          formatter: name => {
            let value = 0;
            if (name === 'Active') {
              value = this.statusComparison.active;
            }
            if (name === 'Loyal') {
              value = this.statusComparison.loyal;
            }
            if (name === 'Hold') {
              value = this.statusComparison.outstanding;
            }
            let percentage = ((value / this.totalClients) * 100).toFixed(2);
            percentage = isNaN(percentage) ? 0 : percentage;
            return `{a|${name} -}  {b|${value}} {c|(${percentage}%)}`;
          },
          textStyle: {
            color: this.color,
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: 'normal',
                fontSize: 15,
              },
              b: {
                height: 20,
                fontWeight: 'bold',
                fontSize: 15,
              },
              c: {
                height: 20,
                fontWeight: 'bold',
                fontSize: 15,
              },
            },
          },
        },
        series: [
          {
            name: 'Status',
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['50%', '38%'],
            avoidLabelOverlap: true,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
              borderColor: this.borderColor,
              borderWidth: 3,
            },
            label: {
              show: true,
              position: 'center',
              fontWeight: 'bold',
              formatter: `{a|${this.totalClients}}\n  Total \n Clients`,
              color: this.color,
              rich: {
                a: {
                  fontSize: 20,
                  fontWeight: '600',
                  color: this.color,
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.statusComparison.active,
                name: 'Active',
                itemStyle: {
                  color: '#00DD31',
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.statusComparison.loyal,
                name: 'Loyal',
                itemStyle: {
                  color: '#0090E7',
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.statusComparison.outstanding,
                name: 'Hold',
                itemStyle: {
                  color: '#FF9900',
                  borderColor: this.borderColor,
                },
              },
            ],
          },
        ],
      };
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.to-negotiate-chart {
  .echarts {
    width: 100% !important;
    margin: auto;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-chart-negotiate"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"chart-card"},[_c('StatusPaidChart',{attrs:{"status-comparison":{
            active: _vm.negotiate[0].totalClients,
            loyal: _vm.negotiate[1].totalClients,
            outstanding: _vm.negotiate[2].totalClients,
          }}})],1)]),_c('b-col',[_c('div',{staticClass:"chart-card"},[_c('MoneyStatusPaidChart',{attrs:{"money-comparison":{
            active: _vm.negotiate[0].amount,
            loyal: _vm.negotiate[1].amount,
            outstanding: _vm.negotiate[2].amount,
          }}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="retainer-fee-line-chart">
    <div
      v-b-tooltip.hover="!selectedYear ? '' : 'Return to Years'"
      class="actions-chart"
      :class="selectedYear ? 'bg-success cursor-pointer' : 'bg-secondary'"
      @click="returnToYears()"
      :disabled="!selectedYear"
    >
      <feather-icon icon="CalendarIcon" size="20" />
    </div>
    <e-charts ref="retainerFeeLine" :options="chartOptions" autoresize />
  </div>
</template>
<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/title";
import "echarts/lib/chart/line";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service";

export default {
  components: {
    ECharts,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      anualRetainerFeeData: [],
      selectedYear: null,
      chartKey: 1,
    };
  },
  computed: {
    color() {
      return this.isDarkSkin ? "#ffffff" : "#606060";
    },
    dataYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 4; i >= 0; i--) {
        years.push(currentYear - i);
      }
      return years;
    },
    dataMonths() {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    },
    xAxisData() {
      if (this.selectedYear) {
        return this.dataMonths;
      }
      return this.dataYears;
    },
    chartOptions() {
      return {
        title: {
          text: `Retainer Fee ${
            this.selectedYear ? `by months (${this.selectedYear})` : "by years"
          }`,
          left: 55,
          top: 10,
          textStyle: {
            color: this.color,
            fontSize: 16,
            fontWeight: "600",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let tooltipString = "";
            const month = params[0].axisValue;
            tooltipString += `<div>${month}</div>`;
            params.forEach((param) => {
              let { value } = param;
              const { color } = param;
              const { seriesName } = param;
              value = this.formatCurrency(value);
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center; gap: 1.5rem;">';
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center;">';
              tooltipString += `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;"></span>`;
              tooltipString += `<span>${seriesName}</span>`;
              tooltipString += "</div>";
              tooltipString += `<strong>${value}</strong>`;
              tooltipString += "</div>";
            });
            return tooltipString;
          },
        },
        legend: {
          data: ["Fee", "Payments", "Pending"],
          color: ["#0090E7", "#00DD31", "#FF9900"],
          textStyle: {
            color: this.color,
            fontSize: 14,
            fontWeight: "600",
          },
          itemWidth: 25,
          itemHeight: 25,
          itemGap: 12,
          right: 25,
          icon: "roundRect",
          iconOption(name, itemStyle) {
            const borderRadius = [12, 12, 12, 12];
            return {
              width: itemStyle.itemWidth,
              height: itemStyle.itemHeight,
              borderRadius,
            };
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData,
          axisLabel: {
            color: this.color,
            margin: 10,
            fontSize: 14,
            fontWeight: "500",
          },
          triggerEvent: true,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            margin: 10,
            fontSize: 15,
            fontWeight: "500",
            color: this.color,
            formatter: (value) => `$${value.toLocaleString()}`,
          },
          nameTextStyle: {
            color: this.color,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.color,
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
        },
        series: [
          {
            name: "Fee",
            color: "#0090E7",
            data: this.feeData,
            type: "line",
            smooth: true,
            symbol: "none",
          },
          {
            name: "Payments",
            color: "#00DD31",
            data: this.paymentsData,
            type: "line",
            smooth: true,
            symbol: "none",
          },
          {
            name: "Pending",
            color: "#FF9900",
            data: this.pendingData,
            type: "line",
            smooth: true,
            symbol: "none",
          },
        ],
      };
    },
    feeData: {
      get() {
        if (
          !this.anualRetainerFeeData ||
          this.anualRetainerFeeData.length === 0
        ) {
          return [];
        }
        return this.anualRetainerFeeData.map((item) => item.fee);
      },
      set(newValue) {
        this.anualRetainerFeeData = newValue.map((value) => ({ fee: value }));
      },
    },
    paymentsData: {
      get() {
        if (
          !this.anualRetainerFeeData ||
          this.anualRetainerFeeData.length === 0
        ) {
          return [];
        }
        return this.anualRetainerFeeData.map((item) => item.payments);
      },
      set(newValue) {
        this.anualRetainerFeeData = newValue.map((value) => ({
          payments: value,
        }));
      },
    },
    pendingData: {
      get() {
        if (
          !this.anualRetainerFeeData ||
          this.anualRetainerFeeData.length === 0
        ) {
          return [];
        }
        return this.anualRetainerFeeData.map((item) => item.pending);
      },
      set(newValue) {
        this.anualRetainerFeeData = newValue.map((value) => ({
          pending: value,
        }));
      },
    },
  },
  created() {
    this.getDebtSolutionAnnualGraphicFee();
  },
  mounted() {
    this.handleChartClick();
  },
  methods: {
    async getDebtSolutionAnnualGraphicFee() {
      try {
        this.addPreloader();
        this.anualRetainerFeeData = [];
        const params = {
          year: this.selectedYear,
          program_id: this.programId,
        };
        const { data } = await DashboardService.getDebtSolutionAnnualGraphicFee(
          params
        );
        this.anualRetainerFeeData = data.map((item) => ({
          ...item,
          fee: (item.fee || "0.00").replace(/,/g, ""),
          payments: (item.payments || "0.00").replace(/,/g, ""),
          pending: (item.pending || "0.00").replace(/,/g, ""),
        }));
        this.chartKey += 1;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
    handleChartClick() {
      this.$nextTick(() => {
        const { chart } = this.$refs.retainerFeeLine;
        chart.getZr().on("click", async (params) => {
          const xAxisValue = params?.target?.eventData?.value || null;
          if (
            !this.dataYears.includes(parseInt(xAxisValue)) ||
            this.selectedYear
          ) {
            return;
          }
          this.selectedYear = xAxisValue;
          await this.getDebtSolutionAnnualGraphicFee();
        });
      });
    },
    async returnToYears() {
      if (!this.selectedYear) return;
      this.selectedYear = null;
      await this.getDebtSolutionAnnualGraphicFee();
    },
  },
};
</script>
<style lang="scss">
.retainer-fee-line-chart {
  position: relative;
  width: 100%;
  .echarts {
    width: 100% !important;
    margin: auto;
  }
  .actions-chart {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 20px;
    z-index: 999;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;
    color: #fff;
  }
}
</style>

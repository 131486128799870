<template>
  <div class="to-negotiate-chart">
    <e-charts ref="line" :options="chartOptions" autoresize />
  </div>
</template>
<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/title";
import "echarts/lib/chart/pie";
export default {
  components: {
    ECharts,
  },
  props: {
    moneyComparison: {
      type: Object,
      required: false,
      default: () => {
        return {
          active: 0,
          loyal: 0,
          outstanding: 0,
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    borderColor() {
      return this.isDarkSkin ? "#111113" : "#ffffff";
    },
    color() {
      return this.isDarkSkin ? "#ffffff" : "#606060";
    },
    totalMoney() {
      return (
        this.moneyComparison.active +
        this.moneyComparison.loyal +
        this.moneyComparison.outstanding
      );
    },
    chartOptions() {
      return {
        title: {
          text: "Money Comparison",
          left: 20,
          top: 10,
          textStyle: {
            color: this.color,
            fontSize: 16,
            fontWeight: "600",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            let tooltipString = "";
            let status = params.data.name;
            let value = params.data.value;
            let percentage = params.percent;
            let color = params.color;
            value = this.formatCurrency(value);
            tooltipString = `<div> Status </div>
                       <div style="display: flex; justify-content: space-between; align-items: center; gap: 1.5rem;">
                       <div style="display: flex; justify-content: space-between; align-items: center;">
                       <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;"></span>
                       <span>${status}</span>
                       </div>
                       <strong>${value} (${percentage}%)</strong>
                       </div>`;

            return tooltipString;
          },
        },
        legend: {
          data: ["Active", "Loyal", "Hold"],
          itemHeight: 25,
          itemWidth: 25,
          bottom: "2%",
          left: "center",
          orient: "vertical",
          color: ["#00DD31", "#0090E7", "#FF9900"],
          formatter: (name) => {
            let value = 0;
            if (name === "Active") {
              value = this.moneyComparison.active;
            }
            if (name === "Loyal") {
              value = this.moneyComparison.loyal;
            }
            if (name === "Hold") {
              value = this.moneyComparison.outstanding;
            }
            let percentage = ((value / this.totalMoney) * 100).toFixed(2);
            percentage = isNaN(percentage) ? 0 : percentage;
            return `{a|${name} -}  {b|${this.formatCurrency(
              value
            )}} {c|(${percentage}%)}`;
          },
          textStyle: {
            color: this.color,
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: "normal",
                fontSize: 15,
              },
              b: {
                height: 20,
                fontWeight: "bold",
                fontSize: 15,
              },
              c: {
                height: 20,
                fontWeight: "bold",
                fontSize: 15,
              },
            },
          },
        },
        series: [
          {
            name: "Status",
            type: "pie",
            radius: ["30%", "50%"],
            center: ["50%", "38%"],
            avoidLabelOverlap: true,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
              borderColor: this.borderColor,
              borderWidth: 3,
            },
            label: {
              show: true,
              position: "center",
              fontWeight: "bold",
              formatter: `{a|${this.formatCurrency(
                this.totalMoney
              )}}\n  Total \n Money`,
              color: this.color,
              rich: {
                a: {
                  fontSize: 14,
                  fontWeight: "600",
                  color: this.color,
                },
              },
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.moneyComparison.active,
                name: "Active",
                itemStyle: {
                  color: "#00DD31",
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.moneyComparison.loyal,
                name: "Loyal",
                itemStyle: {
                  color: "#0090E7",
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.moneyComparison.outstanding,
                name: "Hold",
                itemStyle: {
                  color: "#FF9900",
                  borderColor: this.borderColor,
                },
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    formatCurrency(value) {
      if (typeof value === "string") {
        value = value.replace(/,/g, "");
      }

      value = parseFloat(value);
      if (!value || value === 0 || isNaN(value)) {
        return "$0.00";
      }
      return value.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        },
        { minimumFractionDigits: 2 },
        { maximumFractionDigits: 2 }
      );
    },
  },
};
</script>
<style lang="scss">
.to-negotiate-chart {
  .echarts {
    width: 100% !important;
    margin: auto;
  }
}
</style>

<template>
  <div class="main-chart-negotiate">
    <b-row>
      <b-col>
        <div class="chart-card">
          <StatusPaidChart
            :status-comparison="{
              active: negotiate[0].totalClients,
              loyal: negotiate[1].totalClients,
              outstanding: negotiate[2].totalClients,
            }"
          />
        </div>
      </b-col>
      <b-col>
        <div class="chart-card">
          <MoneyStatusPaidChart
            :money-comparison="{
              active: negotiate[0].amount,
              loyal: negotiate[1].amount,
              outstanding: negotiate[2].amount,
            }"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import MoneyStatusPaidChart from "@/views/debt-solution/views/dashboard/components/negotiate/graphics/MoneyStatusPaidChart.vue";
import StatusPaidChart from "@/views/debt-solution/views/dashboard/components/negotiate/graphics/StatusPaidChart.vue";
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service.js";
export default {
  components: {
    MoneyStatusPaidChart,
    StatusPaidChart,
  },
  data() {
    return {
      negotiate: [
        {
          statusName: "ACTIVE",
          amount: 0,
          totalClients: 0,
          status: 1,
        },
        {
          statusName: "LOYAL",
          amount: 0,
          totalClients: 0,
          status: 5,
        },
        {
          statusName: "HOLD",
          amount: 0,
          totalClients: 0,
          status: 2,
        },
      ],
    };
  },
  created() {
    this.getNegotiate();
  },
  methods: {
    async getNegotiate() {
      try {
        this.addPreloader();
        const data = await DashboardService.getDebtSolutionBalance();

        this.negotiate = this.negotiate.map((item) => {
          const element = data.find((el) => el.status_id === item.status);
          if (element) {
            return {
              ...item,
              amount: this.parseAmount(element.general_balance),
              totalClients: parseInt(element.general_amount_of_clients),
              statusName: element.status_name.toUpperCase(),
            };
          }
          return item;
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    parseAmount(amount) {
      if (typeof amount === "string") {
        amount = amount.replace(/,/g, "");
      }

      return (amount = parseFloat(amount));
    },
  },
};
</script>
<style lang="scss">
.main-chart-negotiate {
  width: 100%;
  .chart-card {
    box-shadow: 0px 0px 12px 0px #0000001a;
    padding: 1rem 0;
    border-radius: 0.3rem;
  }
}

.dark-layout {
  .main-chart-negotiate {
    .chart-card {
      background: #111113;
    }
  }
}
</style>
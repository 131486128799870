<template>
  <div class="main-charts-retainer-fee">
    <line-chart :program-id="programId" />
  </div>
</template>
<script>
import LineChart from "@/views/debt-solution/views/dashboard/components/retainer-fee/graphics/LineChart.vue";
export default {
  components: {
    LineChart,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.main-charts-retainer-fee {
  width: 100%;
  height: 468px;
  box-shadow: 0px 0px 12px 0px #0000001a;
  padding: 1rem 0;
  border-radius: 0.3rem;
}
.dark-layout {
  .main-charts-retainer-fee {
    background: #111113;
  }
}
</style>
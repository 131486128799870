<template>
  <div id="id-main-charts-other-fee" class="main-charts-other-fee">
    <div id="id-other-fee-line-chart" class="other-fee-line-chart">
      <div
        v-b-tooltip.hover="!selectedYear ? '' : 'Return to Years'"
        class="actions-chart"
        :class="selectedYear ? 'bg-success cursor-pointer' : 'bg-secondary'"
        @click="returnToYears()"
        :disabled="!selectedYear"
      >
        <feather-icon icon="CalendarIcon" size="20" />
      </div>
      <e-charts ref="otherFeeChart" :options="chartOptions" autoresize />
    </div>
  </div>
</template>
<script>
import DashboardService from "@/views/debt-solution/views/dashboard/service/dashboard.service";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/chart/line";

export default {
  components: {
    ECharts,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      annualOtherData: [],
      selectedYear: null,
    };
  },
  computed: {
    borderColor() {
      return this.isDarkSkin ? "#17171a" : "#ffffff";
    },
    color() {
      return this.isDarkSkin ? "#ffffff" : "#606060";
    },
    dataYears() {
      const years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 4; i >= 0; i--) {
        years.push(currentYear - i);
      }
      return years;
    },
    dataMonths() {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    },
    xAxisData() {
      if (this.selectedYear) {
        return this.dataMonths;
      }
      return this.dataYears;
    },
    chartOptions() {
      return {
        title: {
          text: `Others ${
            this.selectedYear ? `by months (${this.selectedYear})` : "by years"
          }`,
          left: 55,
          top: 10,
          textStyle: {
            color: this.color,
            fontSize: 16,
            fontWeight: "600",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let tooltipString = "";
            const month = params[0].axisValue;
            tooltipString += `<div>${month}</div>`;
            params.forEach((param) => {
              const isVoidOrCb =
                ["Charge Back", "Void/Refund"].includes(param.seriesName) &&
                param.value > 0;
              let { value } = param;
              const { color } = param;
              const { seriesName } = param;
              value = this.setCurrencyZero(value);
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center; gap: 1.5rem;">';
              tooltipString +=
                '<div style="display: flex; justify-content: space-between; align-items: center;">';
              tooltipString += `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:5px;"></span>`;
              tooltipString += `<span>${seriesName}</span>`;
              tooltipString += "</div>";
              tooltipString += `<strong>${
                isVoidOrCb ? "-" : ""
              }$${value}</strong>`;
              tooltipString += "</div>";
            });
            return tooltipString;
          },
        },
        legend: {
          data: ["Other Fees", "Void/Refund", "Charge Back"],
          color: ["#0090E7", "#FF8E00", "#E70000"],
          itemHeight: 25,
          itemWidth: 25,
          right: 25,
          top: 10,
          formatter: (name) => `{a|${name}}`,
          textStyle: {
            color: this.color,
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: "600",
                fontSize: 15,
              },
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.xAxisData,
          axisLabel: {
            color: this.color,
            margin: 10,
            fontSize: 14,
            fontWeight: "500",
          },
          triggerEvent: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.color,
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
          axisLabel: {
            margin: 10,
            fontSize: 15,
            fontWeight: "500",
            color: this.color,
            formatter(value) {
              return `$${value.toLocaleString()}`;
            },
          },
          nameTextStyle: {
            color: this.color,
          },
        },
        series: [
          {
            data: this.annualAdditionalCharges,
            type: "bar",
            name: "Other Fees",
            barWidth: 8,
            barGap: "20%",
            color: "#00DD31",
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            data: this.annualVoidRefunds,
            type: "bar",
            name: "Void/Refund",
            barWidth: 8,
            barGap: "20%",
            color: "#FF8E00",
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
          {
            data: this.annualChargeBacks,
            type: "bar",
            name: "Charge Back",
            barWidth: 8,
            barGap: "20%",
            color: "#E70000",
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
              },
            },
          },
        ],
      };
    },
    annualAdditionalCharges() {
      return this.annualOtherData.map((a) => a.additional_charges);
    },
    annualChargeBacks() {
      return this.annualOtherData.map((a) => a.charge_backs);
    },
    annualVoidRefunds() {
      return this.annualOtherData.map((a) => a.void_refund);
    },
  },
  async created() {
    await this.getAnnualOtherData();
  },
  mounted() {
    this.handleChartClick();
  },
  methods: {
    async getAnnualOtherData() {
      try {
        this.addPreloader();
        this.annualOtherData = [];
        const params = {
          year: this.selectedYear,
          program_id: this.programId,
        };
        const { data } =
          await DashboardService.getDebtSolutionAnnualGraphicOthers(params);
        this.annualOtherData = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    setCurrencyZero(number) {
      function formatPrice(value) {
        const val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (number) {
        return formatPrice(number);
      }
      return "0.00";
    },
    handleChartClick() {
      this.$nextTick(() => {
        const { chart } = this.$refs.otherFeeChart;
        chart.getZr().on("click", (params) => {
          const xAxisValue = params?.target?.eventData?.value || null;
          if (
            !this.dataYears.includes(parseInt(xAxisValue)) ||
            this.selectedYear
          ) {
            return;
          }
          this.selectedYear = xAxisValue;
          this.getAnnualOtherData();
        });
      });
    },
    async returnToYears() {
      if (!this.selectedYear) return;
      this.selectedYear = null;
      await this.getAnnualOtherData();
    },
  },
};
</script>
<style lang="scss">
.other-fee-line-chart {
  position: relative;
  .echarts {
    width: 100% !important;
    margin: auto;
  }
  .actions-chart {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 20px;
    z-index: 999;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;
    color: #fff;
  }
}
.main-charts-other-fee {
  width: 100%;
  height: 462px;
  box-shadow: 0px 0px 12px 0px #0000001a;
  padding: 1rem 0;
  border-radius: 0.3rem;
}
.dark-layout {
  .main-charts-other-fee {
    background: #111113;
  }
}
</style>
